@import "normalize";

h1, h2, h3 {
  font-family: 'Oswald', 'Arial Narrow', sans-serif;
  margin: 0;
}

a {
  color: rgb(31, 138, 255);

  &:hover {
    text-decoration: none;
  }
}

p {
  margin: .5em 0;
}

html {
  height: 100%;
}

body {
  font: 16px/1.4 Helvetica, Arial, sans-serif;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #383838 url('../img/loader.svg') no-repeat 50%/32px;
  transition: background-color 1s ease-out;
  .loaded & {
    background: #383838;
  }
}

.wrap {
  box-shadow: 0 0 1px 1px rgba(0,0,0,.3);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  animation-duration: 1s;
  animation-fill-mode: both;
  transform: perspective(400px);
  background: #fff;
  max-width: 480px;
  opacity: 0;

  .loaded & {
    backface-visibility: visible;
    animation-name: flipInX;
    opacity: 1;
  }
}

header {
  text-align: center;
  margin-bottom: 1.5em;
  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background: rgba(0,0,0,.3);
    margin-bottom: .5em;
    box-shadow: 0 0 0 5px #fff, 0 0 1px 6px rgba(0,0,0,.5);
  }
}

section {
  margin-bottom: 1.5em;
  &:last-child {
    margin-bottom: 0;
  }
}

.skill {
  margin-bottom: .3em;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: top;
  img {
    width: 1.2em;
    height: 1.2em;
    object-fit: contain;
    margin-right: .5ex;
  }
}

@media screen and (max-width: 740px){

  .wrap {
    transition: opacity 1s ease-out;

    .loaded & {
      backface-visibility: visible;
      animation-name: none;
      opacity: 1;
    }
  }
}

@import "animations";
